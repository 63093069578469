// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-formular-js": () => import("./../../../src/pages/formular.js" /* webpackChunkName: "component---src-pages-formular-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-letsdeal-js": () => import("./../../../src/pages/letsdeal.js" /* webpackChunkName: "component---src-pages-letsdeal-js" */),
  "component---src-pages-priser-js": () => import("./../../../src/pages/priser.js" /* webpackChunkName: "component---src-pages-priser-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-utbildningar-js": () => import("./../../../src/pages/utbildningar.js" /* webpackChunkName: "component---src-pages-utbildningar-js" */)
}

